import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/beat-rising-interest-rates-home-buyer";

  return (
    <LayoutWrapper
      title="How to Beat Rising Interest Rates as a Home Buyer"
      desc="When you’re ready to buy a home, there are several steps to take to lower your mortgage rate. Read on to learn how to beat rising interest rates!"
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              How to Beat Rising Interest Rates as a Home Buyer
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  December 19, 2022
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_How to Beat Rising Interest Rates as a Home Buyer.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                When you’re ready to buy a home, the terms of your home loan will determine how much
                house you can afford. One factor to address is the rate of interest your lender will
                charge.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Fortunately, there are several steps you can take to lower your mortgage rate.
                First, take a look at the following methods for combating high-interest rates.{" "}
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Buy Discount Points
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Consider paying a little more up front when looking at how to beat rising interest
                rates as a home buyer. For example, you can buy discount points at 1% of your home’s
                purchase price.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Points on a $200,000 home will cost you $2,000 each. So you’ll lower your interest
                rate by around 0.25% for every point.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Keep in mind that each lender is different. Therefore, depending on your chosen
                mortgage company, your discount may be more or less than the 0.25% average discount.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Reduce Your Loan Term
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Changing your loan length is another way to beat rising interest rates as a home
                buyer.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Remember that a 15- or 20-year loan will result in higher monthly payments. However,
                more of that amount will go toward the principal since shorter loans typically come
                with lower interest rates. Lower rates will help you build equity faster than you
                would with a 30-year loan.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Increase the Size of Your Down Payment
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Another way to beat rising interest rates as a home buyer is to increase the size of
                your down payment. Putting 20% or more down on your home purchase will show lenders
                that you are a better risk.{" "}
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                In exchange for your good faith effort, lenders may lower your interest rate and
                forgo the need to assess the property. You might also be able to buy the home
                without obtaining private mortgage insurance (PMI).
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Start Small and Upgrade Later
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Many younger adults find that the answer to beating rising interest rates as a home
                buyer is to choose a starter home. Alternatively, buy a fixer-upper that costs less
                in a competitive market. Since your home purchase price is lower, you’ll pay it off
                faster and pay less in interest.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Later, you can keep the house and use it as a rental property or sell it outright.
                Either option will give you the resources to upgrade to a bigger home in a few
                years.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Buy a Multi-Family Home
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Rather than reducing your mortgage interest rate, consider increasing your income to
                help you afford the interest more comfortably. For example, a home that has two to
                three units will provide you with living space for yourself while giving you a
                rental income from the other units.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As you build more equity in your home, you can upgrade to a single-family home and
                keep that first property as an investment. Renting all the units will give you a
                steady stream of passive income.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Put Your Home Search in the Hands of the Pros
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
